import './App.css';
import MainPage from './views/mainpage';



const App = () => {
  return (
    <MainPage />
  )
}

export default App;
