import React from "react";

import "../styles/styles.css"

const Footer=()=>{
  return(
    <footer id="footer">
     <div className="inner">
      <h2>EduLink Global</h2>
      <p>Innovation in Education</p>
      </div>
  </footer> 

  )
};

export default Footer;